.tell-us-container {
    border-top: 2px solid #e2e2e2;
    margin-top: 100px;
    padding: 20px 30px;
    padding-bottom: 100px;

    h2 {
        font-size: 40px;
        font-weight: 500;
    }


    .form-item {
        position: relative;
        margin-top: 30px;

        input {
            width: 50%;
            height: 40px;
            font-size: 16px;
            transition: 0.6s;
            border: none;
            border-bottom: 2px solid #CCC;
            background-color: transparent;
            z-index: 10;
            position: relative;

            &:focus {
                outline: none;
                border-bottom: 2px solid #2e2e2e;
            }
        }

        label {
            color: #CCC;
            position: absolute;
            cursor: text;
            transform: translateY(-25px);
            transition: transform 0.3s ease;
            left: 0;
            bottom: -15px;

            &.expanded {
                transform: translateY(-55px);
                font-size: 15px;
                font-weight: 600;
                color: #2e2e2e
            }
        }

    }

    button {
        border: none;
        padding: 15px 25px;
        font-size: 18px;
        font-weight: 700;
        color: #fff;
        background-color: #FFA53B;
        transition: .5s all;

        &:hover {
            transform: scale(1.1);
            background-color: lighten($color: #FFA53B, $amount: 5%);
        }
    }

    .form-description {
        position: relative;
        margin-top: 30px;
        display: flex;
        flex-direction: column;

        label {

            font-size: 15px;
            font-weight: 600;
            color: #2e2e2e
        }

        textarea {
            resize: none;
            border-color: #CCC;
        }
    }

    .form-images {
        position: relative;
        margin-top: 20px;
        display: flex;
        flex-direction: column;

        label {

            font-size: 15px;
            font-weight: 600;
            color: #2e2e2e
        }

        input{
            margin: 30px 0;
        }
    }




    .pop-up{
        display: none;
        position: fixed;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 196, 204, 0.5);
        z-index: 9999;
        top: 0;
        left: 0;
        backdrop-filter: blur(10px);

        .popup-container{
            width: 100%;
            max-width: 700px;
            height: 300px;
            background-color: #fff;
            box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
            display: flex;
            flex-direction: column;
            gap: 20px;
            padding: 30px;

            h2 {
                font-size: 40px;
                color: #FFA53B;
                margin-bottom: 0;
            }

            p {
                font-size: 18px;
                line-height: 28px;
            }

            button {
                border: none;
                padding: 15px 25px;
                font-size: 18px;
                font-weight: 700;
                color: #fff;
                background-color: #FFA53B;
                transition: .5s all;
                max-width: 200px;
                margin: auto;

                &:hover {
                    transform: scale(1.1);
                    background-color: lighten($color: #FFA53B, $amount: 5%);
                }
            }
            
        }

        &.loader-popup{
            .spinner {
                width: 86px;
                height: 86px;
                display: grid;
                border: 4px solid #0000;
                border-radius: 50%;
                border-color: #ffec9e #0000;
                animation: spinner-e04l1k 3s infinite linear;
              }
              
              .spinner::before,
              .spinner::after {
                content: "";
                grid-area: 1/1;
                margin: 2.2px;
                border: inherit;
                border-radius: 50%;
              }
              
              .spinner::before {
                border-color: #FFA53B #0000;
                animation: inherit;
                animation-duration: 2s;
                animation-direction: reverse;
              }
              
              .spinner::after {
                margin: 8.9px;
              }
        }

        &.terms-popup{
            .popup-container{
                height: auto;
                max-height: 70vh;
                overflow-y: auto;
                p{
                    margin: 0;
                }
            }
        }
        
        &.show{
            display: flex;
            justify-content: center;
            align-items: center;

        }

        
    }
}