.colorPicker-container {

    button {
        border: none;
        padding: 15px 25px;
        font-size: 18px;
        font-weight: 700;
        color: #fff;
        background-color: #00C4CC;
        transition: .5s all;

        &:hover {
            transform: scale(1.1);
            background-color: lighten($color: #00C4CC, $amount: 5%);
        }
    }

    .selected-colors {
        display: flex;
        gap: 20px;

        .colors {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .color-cont {
                display: flex;
                gap: 10px;
                justify-content: center;
                align-items: center;

                .color {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    border: 2px solid #e2e2e2;
                }

                p {
                    font-size: 12px;
                    text-transform: uppercase;
                    font-weight: 600;
                    color: #00C4CC
                }
            }
        }

        .change-btn {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .alert {
        display: none;
        position: fixed;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 196, 204, 0.5);
        z-index: 9999;
        top: 0;
        left: 0;
        backdrop-filter: blur(10px);

        .alert-box {
            width: 100%;
            max-width: 700px;
            height: 300px;
            background-color: #fff;
            box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
            display: flex;
            flex-direction: column;
            gap: 20px;
            padding: 30px;

            h2 {
                font-size: 40px;
                color: #00C4CC;
                margin-bottom: 0;
            }

            p {
                font-size: 18px;
                line-height: 28px;
            }

            button {
                max-width: 200px;
                margin: auto;
            }
        }

        &.show {
            display: flex;
            justify-content: center;
            align-items: center;

        }
    }

    .color-picker-overlay {
        display: none;
        position: fixed;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 196, 204, 0.5);
        z-index: 9999;
        top: 0;
        left: 0;
        backdrop-filter: blur(10px);

        .color-picker-box {
            width: 100%;
            max-width: 1000px;
            height: 400px;
            background-color: #fff;
            box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
            display: flex;
            gap: 20px;
            border-radius: 10px;

            @media (max-width: 980px) {
                max-width: 800px;
            }

            @media (max-width: 450px) {
                flex-direction: column;
                height: 90vh;
            }

            .preview {
                width: 40%;

                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #e6e6e6;
                border-radius: 10px 0 0 10px;

                @media (max-width: 980px) {
                    width: 30%;
                }

                @media (max-width: 450px) {
                    width: 100%;

                    div {
                        width: 40%;
                        margin: 10px;
                    }
                }
            }

            .colors {
                width: 60%;

                @media (max-width: 980px) {
                    width: 70%;
                }

                @media (max-width: 450px) {
                    width: 96%;
                    padding: 2%;
                    display: flex;
                    flex-direction: column;
                }

                h3 {
                    font-weight: 400;
                }

                .color-container {
                    display: flex;
                    gap: 8px;
                    margin-bottom: 50px;
                    flex-flow: wrap;

                    @media (max-width: 450px) {
                        margin-bottom: 25px;
                    }

                    .color-option {
                        width: 20px;
                        height: 20px;
                        border-radius: 50%;
                        border: 2px solid #e2e2e2;
                        transition: .5s all;

                        &:hover {
                            transform: scale(1.2);
                        }

                        &.selected {
                            box-shadow: 0px 0px 5px rgba(0, 0, 0, 1);
                            transform: scale(1.2);
                        }

                    }
                }

                @media (max-width: 450px) {
                    h3 {
                        text-align: center;
                    }

                    .color-container {
                        justify-content: center;
                    }

                    button {
                        margin: auto;
                    }
                }

            }
        }

        &.show {
            display: flex;
            justify-content: center;
            align-items: center;

        }
    }
}