.circulo-container{
    svg{
        path{
            transition: 1s all;
        }
        .nombre{
            font-size: 350%;
            font-family: 'Noteworthy';
            font-weight: 700;
            stroke: #e2e2e2;
            stroke-width: 0.5px;
        }

        .telefono{
            font-size: 250%;
            font-family: Arial, Helvetica, sans-serif;
            font-weight: 700;
            stroke: #e2e2e2;
            stroke-width: 0.5px;
        }
        
    }
}