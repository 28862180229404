.home-nav-container {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    padding: 30px;
    max-width: 2000px;
    margin: auto;
    @media (max-width: 980px){
        padding: 10px;
        padding-top: 30px;
        gap: 10px;
    }

    @media (max-width: 450px) {
        padding: 10px;
        gap: 10px;
    }

    .link {
        width: 33%;
        height: 205px;
        background-color: #eeeeee;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        font-size: 24px;
        color: #fff;
        transition: .5s all;
        z-index: 1;

        &:hover{
            transform: scale(1.05);
            z-index:2 ;
        }

        &.link-plaquitas{
            background-color: #00c4cc;
        }

        &.link-macetitas{
            background-color: #FF738E;
        }

        &.link-modelos{
            background-color: #A548FF;
        }

        &.link-flips{
            background-color: #FFD93B;
        }

        &.link-who-we-are{
            background-color: #FFA53B;
        }

        &.link-pla{
            background-color: #6422B8;
        }

        @media (max-width: 980px){
            width: 49%;
        }

        @media (max-width: 450px) {
            width: 100%;
        }
    }


    .pop-up{
        display: none;
        position: fixed;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 196, 204, 0.5);
        z-index: 9999;
        top: 0;
        left: 0;
        backdrop-filter: blur(10px);

        .popup-container{
            display: flex;
            justify-content: center;

            align-items: center;
            flex-direction: column;
            img{
                max-width: 1080px;
                width: 90%;
                margin: 40px;
                display: none;
            

                &.show{
                    display: block;
                }
            }

            button {
                border: none;
                padding: 15px 25px;
                font-size: 18px;
                font-weight: 700;
                color: #fff;
                background-color: #FFA53B;
                transition: .5s all;
                max-width: 200px;
                margin: auto;

                &:hover {
                    transform: scale(1.1);
                    background-color: lighten($color: #FFA53B, $amount: 5%);
                }
            }
            
        }

        &.show{
            display: flex;
            justify-content: center;
            align-items: center;

        }

        
    }
}